/* ****************************Submit Form Button Loader****************************** */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  margin: auto;
  content: "";
  animation: spinner 0.8s linear infinite;
  border: 3px solid var(--color-white);
  border-top-color: var(--lightBlack);
  border-radius: var(--rd-circle);
}

.spinner--small:before {
  position: absolute;
  top: 50%;
  left: 50%;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  margin-top: -16px;
  content: "";
  animation: spinner 0.8s linear infinite;
  border: 3px solid var(--selectedColor);
  border-top-color: var(--mainColor);
  border-radius: var(--rd-circle);
  margin-inline-end: -10px;

}

/* ****************************Submit Form Button Loader****************************** */

/* -------Square Loading Spinner------ */
@keyframes square-loading-animation {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, .9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow {
  50% {
    transform: scale(1.2, 1);
  }
}

@keyframes loader {
  0% {
    left: -100px
  }
  100% {
    left: 110%;
  }
}


/* -------Start Sessions Animation------ */
@keyframes glow {
  0% {
    box-shadow: 0 0 15px rgb(34 172 184), 0 0 16px rgb(34 172 184);
  }
  50% {
    box-shadow: 0 0 15px rgb(81, 224, 210), 0 0 16px rgb(81, 224, 210);
  }
  100% {
    box-shadow: 0 0 15px rgb(34, 172, 184), 0 0 16px rgb(34, 172, 184);
  }
}
