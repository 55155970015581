html, body {
  height: 100%;
}

html[dir=rtl] {
  * {
    line-height: 1.5;
  }
}

* {
  font-family: var(--font-family);
  line-height: normal;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none !important;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-family);
  overflow-x: hidden;
  margin: 0;
  color: var(--fontColor);
  background-color: var(--color-white);
  scroll-behavior: smooth;
}

*::selection {
  color: var(--fontColor);
  background: var(--oto-blue);

}

a {
  text-decoration: none !important;
}

img {
  max-width: 100%;
}

ol, ul {
  margin-bottom: 0;
  padding-left: 0 !important;
}


/* HIDE DOWNLOAD BUTTON OF AUDIO */
audio::-internal-media-controls-download-button {
  display: none;
}

audio::-webkit-media-controls-enclosure {
  overflow: hidden;
}

audio::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}
