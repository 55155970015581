@import "src/styles/abstracts/variables";

%btn__main {
  font-size: var(--font-size-20);
  position: relative;
  padding: 0.6rem 1.8rem !important;
  cursor: pointer;
  color: var(--color-white);
  border-color: var(--mainColor) !important;
  border-radius: var(--rd-5);
  background-color: var(--mainColor) !important;
}

%main__background__image {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat !important;
  background-position-x: center !important;
  background-position-y: bottom !important;
  background-size: 100% 100% !important;
}

%btn-book--now {
  position: relative;
  overflow: hidden;
  padding: 0.2rem 2rem;

  &:after {
    position: absolute;
    z-index: -10;
    top: -50px;
    left: -75px;
    width: 50px;
    height: 155px;
    content: "";
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    transform: rotate(35deg);
    opacity: .2;
    background: var(--color-white);
  }

  &:hover {
    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }
}

%paragraph-lines {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

%paragraph-5-lines {
  @extend %paragraph-lines;
  -webkit-line-clamp: 5;
}

%paragraph-3-lines {
  @extend %paragraph-lines;
  color: var(--fontColor);
  -webkit-line-clamp: 3;
}

%paragraph-2-lines {
  @extend %paragraph-lines;
  color: var(--fontColor);
  -webkit-line-clamp: 2;
}

%hoverEffect {
  border-radius: var(--rd-12);
  background: rgba(0, 0, 0, 0.2);
}


%main__container {
  overflow: hidden;
  width: 75%;
  margin: 1rem auto;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

%main__card {
  padding: 1rem;
  border: 2px solid var(--semi-gray);
  border-radius: var(--rd-8);
}

%block__data {
  @include flex(center, center, row, nowrap);
  position: relative;
  margin: 1.5rem auto;
  padding: 1.2rem 1rem 1rem 1rem;
  border: 1px var(--color-gray-0) solid;
  border-radius: var(--rd-16);
}

