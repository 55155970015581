input.mat-mdc-input-element {
  color: var(--mainColor) !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: var(--mainColor) !important;
}

.mat-mdc-form-field-hint {
  color: var(--redColor) !important;
}

.mat-icon.mat-accent {
  color: var(--mainColor) !important;
}

.mat-icon.mat-primary {
  color: var(--mainColor) !important;
}

.mat-drawer-content {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  background: var(--color-white) !important;
}

.mat-drawer-inner-container {
  //width: 175px !important;
  background: #f6f6f6 !important;
}

//mat-input focused color
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: var(--mainColor) !important;
}

// mat-input error outline color
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  opacity: 0.8 !important;
  color: var(--redColor) !important;
}

// mat-input carent color
.mat-mdc-input-element {
  caret-color: var(--oto-blue) !important;
}

// mat-input error carent color
.mat-form-field-invalid .mat-mdc-input-element, .mat-warn .mat-input-element {
  caret-color: var(--oto-blue) !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mat-form-field-flex {
  background: none !important;
}

//mat-input focused color
.mat-form-field-appearance-fill.mat-focused .mat-form-field-fill-thick {
  color: var(--mainColor) !important;
}

/*---------ANGULAR MATERIAL DATE PICKER---------*/

//::ng-deep datepicker-value-example .mat-mdc-form-field-label {
//  color: rgb(170, 156, 156);
//}
//
//::ng-deep .mat-datepicker-toggle {
//  color: blue;
//}
//
//::ng-deep .mat-mdc-form-field-underline {
//  background: red;
//}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-mdc-icon-button .mat-datepicker-toggle-default-icon, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button .mat-datepicker-toggle-default-icon {
  fill: var(--mainColor) !important;
}

/*---------ANGULAR MATERIAL Select Options---------*/

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-value {
  color: var(--mainColor) !important;
}

/*** To change the arrow color ****/
/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-arrow {
  color: var(--mainColor) !important;
  border-top: 5px solid var(--mainColor) !important;
}


// mat-input error outline color
.mat-form-field-appearance-fill.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-fill-thick {
  opacity: 0.8 !important;
  color: var(--redColor) !important;
}

.mat-form-field-appearance-fill {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-ripple {
    background: var(--mainColor) !important;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-focused .mat-form-field-label {
  /*change color of label*/
  color: var(--mainColor) !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-value-text {
  color: var(--mainColor) !important;

}

.mat-mdc-input-element {
  color: var(--mainColor) !important;

}


.mat-mdc-radio-button ~ .mat-radio-button {
  margin-inline-end: 16px !important;
}

/* ****************************App Spinner****************************** */
.spinner-wrapper {
  position: absolute;
  z-index: 5555555555;
  width: 100%;
  height: 100%;
  background-color: var(--color-white);

  .mat-mdc-progress-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

/* ****************************App Spinner******************************* */
/*---------------Mat Dialogue ----------------------*/
.mat-mdc-dialog-container {
  border-radius: var(--rd-30) !important;
}


// to make checkbox responsive
// adds breaks to the mat-checkboxes with long labels
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-layout {
  white-space: normal !important;
}

// rather than center the checkbox, put the checkbox in the first line
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-inner-container {
  margin-top: 3px !important;
}

mat-datepicker-toggle {
  color: var(--mainColor) !important;
}


button.mat-focus-indicator.mat-calendar-next-button.mat-mdc-icon-button.mat-mdc-button-base,
button.mat-focus-indicator.mat-calendar-previous-button.mat-icon-button.mat-button-base, {
  color: var(--mainColor) !important;
}

.mat-calendar-body-selected {
  color: var(--color-white) !important;
}

.mat-badge-content {
  color: var(--color-white) !important;
}


.mat-mdc-button, .mat-mdc-icon-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button {
  border-radius: var(--rd-32) !important;
}


//.mdc-list-item.mdc-list-item--with-one-line {
//  --mdc-list-list-item-one-line-container-height: auto !important;
//}

.mdc-data-table__cell {
  overflow: unset !important;
}


