/* ****************************Scroll BAR****************************** */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--background-color-gray-light);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: var(--rd-10);
  background: var(--mainColor);
  //box-shadow: inset 2px 0 2px  var(--lightBlack), inset -2px -2px 2px  var(--lightBlack);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(#f77d0e, 0.7);
}

/* ****************************Scroll BAR****************************** */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.owl-nav {
  display: none;
}

.owl-dots {
  @include flex(center, center, row, nowrap);

  width: 100%;
  margin: auto;
}

.owl-dot {
  width: 10px;
  height: 10px;
  margin: 0.5rem 0.2rem;
  border-radius: var(--rd-circle);
  background: var(--semi-gray);
}

.owl-dot.active {
  background: var(--color-white);
}

