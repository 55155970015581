@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/*------External Imports-------*/
@import '../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '../../node_modules/ngx-bar-rating/themes/br-stars-theme';

/*------END OF External Imports-------*/

:root {
  /*----------------------- Typography ------------------------*/
  --font-family: 'Montserrat', sans-serif;
  --font-size-10: 0.625rem;
  --font-size-12: 0.75rem;
  --font-size-13: 0.825rem;
  --font-size-14: 0.875rem;
  --font-size-15: 0.9375rem;
  --font-size-16: 1rem;
  --font-size-18: 1.125rem;
  --font-size-19: 1.1875rem;
  --font-size-20: 1.25rem;
  --font-size-23: 1.450rem;
  --font-size-24: 1.5rem;
  --font-size-22: 1.375rem;
  --font-size-28: 1.75rem;
  --font-size-29: 1.825rem;
  --font-size-30: 1.875rem;
  --font-size-32: 2rem;
  --font-size-48: 3rem;

  // Font Wights
  --fw-light: 300;
  --fw-regular: 400;
  --fw-medium: 500;
  --fw-semiBold: 600;
  --fw-bold: 700;
  /*----------------------- Colors ------------------------*/
  --mainColor: #fcb040;
  --oto-green: #8db759;
  --oto-blue: #2bacb8;
  --fontColor: #272727;
  --font-color: #a7a7a7;
  --font-color-light: #757575;
  --selectedColor: #f5cdc3;
  --background-color-gray-light: #f6f6f6;
  --color-white: #ffffff;
  --grey: #808080;
  --lightBlack: #474747;
  --lightBlue: #6d7783;
  --redColor: #cd2238;
  --successColor: #17e283;
  --light-grey: #929293;
  --secOto: #33abb8;
  --color-genio: #b32b37;
  --color-purple: #2e0f38;
  --color-grammar: #8db759;
  --color-writing: #2bacb8;
  --color-speaking: #f05027;
  --color-reading: #5591d8;
  --color-listening: #fcb040;
  --semi-gray: #eeeeee;
  --color-gray-6: #878787;
  --color-gray-5: #444444;
  --color-gray-4: #707070;
  --color-gray-3: #878787;
  --color-gray-2: #9b9b9b;
  --color-gray-1: #f4f4f4;
  --color-gray-0: #c4c4c4;
  --color-black: #000000;
  /*----------------------- Border Radius ------------------------*/
  --rd-circle: 50%;
  --rd-32: 2rem;
  --rd-30: 1.875rem;
  --rd-24: 1.5rem;
  --rd-16: 1rem;
  --rd-12: 0.75rem;
  --rd-11: 0.6875rem;
  --rd-10: 0.625rem;
  --rd-9: 0.5625rem;
  --rd-8: 0.5rem;
  --rd-7: 0.4375rem;
  --rd-6: 0.375rem;
  --rd-5: 0.3125rem;
  --rd-4: 0.25rem;

  /*----------------------- Box Shadows ------------------------*/
  --box-shadow: 0px 3px 41px #00000029;
  --box-shadow-light: 0px 15px 25px #00000029;
  --card-shadow: 0 0 8px -2px rgb(0 0 0 / 50%);

  /*----------------------- Transitions ------------------------*/
  --transition-100ms: 0.2s ease-in-out;
  --transition-200ms: 0.2s ease-in-out;
  --transition-300ms: 0.3s ease-in-out;
  --transition-400ms: 0.4s ease-in-out;
  --transition-500ms: 0.5s ease-in-out;

}


/*------Internal Imports-------*/
@import "abstracts/variables";
@import "abstracts/helperss";

@import 'base/reset';
@import 'base/ng-material';
@import 'base/animation';
@import 'base/placeholders';
@import 'base/overrides';

@import "themes/courses_theme";

.test__image {
  max-width: 500px !important;
}

/*------Ends of Internal Imports-------*/
