@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

@mixin flex($align: center, $justify: center, $flow: row,$wrap:wrap) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-flow: $flow;
  flex-wrap: $wrap;
}

@mixin box-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
  0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

@mixin main__container {
  width: 75%;
  margin: 1rem auto;
  overflow: hidden;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

@mixin breadcrumb__main {
  display: flex;
  font-size: var(--font-size-16);
  padding-top: 0.5rem;

  a {
    color: var(--mainColor);
    text-decoration: none;
    cursor: pointer;
  }

  .breadcrumb__back {
    display: none;
  }

  @include media-breakpoint-down(md) {
    .breadcrumb__back {
      display: block;
    }
  }
}

@mixin note__container($color: var(--mainColor)) {
  .note__container {
    margin-bottom: 0.8rem;

    .note__title {
      opacity: 0.5;
      font-weight: var(--fw-bold);
    }

    .note__body {
      color: $color;

      &.note__link {
        a {
          color: $color;
        }
      }
    }
  }
}

@mixin max-width($w:150px) {
  max-width: $w;
  white-space: break-spaces;
  word-break: break-all;
}
