/* For use in src/lib/core/theming/_palette.scss */
$md-primarycolors: (
  /* For use in src/lib/core/theming/_palette.scss */
  50 : #fff6e8,
  100 : #fee7c6,
  200 : #fed8a0,
  300 : #fdc879,
  400 : #fcbc5d,
  500 : #fcb040,
  600 : #fca93a,
  700 : #fba032,
  800 : #fb972a,
  900 : #fa871c,
  A100 : #ffffff,
  A200 : #fffdfb,
  A400 : #ffe1c8,
  A700 : #ffd3ae,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


/* For use in src/lib/core/theming/_palette.scss */
$md-seconderycolors: (
  50 : #e7f5f6,
  100 : #c2e6ea,
  200 : #99d5dc,
  300 : #70c4cd,
  400 : #52b8c3,
  500 : #33abb8,
  600 : #2ea4b1,
  700 : #279aa8,
  800 : #2091a0,
  900 : #148091,
  A100 : #c5f6ff,
  A200 : #92efff,
  A400 : #5fe7ff,
  A700 : #46e4ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-thirdcolors: (
  50 : #f9e4e7,
  100 : #f0bdc3,
  200 : #e6919c,
  300 : #dc6474,
  400 : #d54356,
  500 : #cd2238,
  600 : #c81e32,
  700 : #c1192b,
  800 : #ba1424,
  900 : #ae0c17,
  A100 : #ffdbdd,
  A200 : #ffa8ad,
  A400 : #ff757c,
  A700 : #ff5c64,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

