@import "variables";
@import "../base/placeholders";

/* ****************************Our Helper Classes****************************** */
.title__green {
  color: var(--color-grammar);
}

.title__red {
  color: var(--color-speaking);
}

.title__blue {
  color: var(--color-writing);
}

.title__purple {
  color: var(--color-purple);
}

.title__genio {
  color: var(--color-genio);
}

.title__yellow {
  color: var(--color-listening);
}

.our-text-primary {
  color: var(--mainColor);
}

.our-text-white {
  color: var(--color-white) !important;
}

.content__grammar {
  color: var(--color-grammar);
}

.content__listening {
  color: var(--color-listening);
}

.content__reading {
  color: var(--color-reading);
}

.content__speaking {
  color: var(--color-speaking);
}

.content__writing {
  color: var(--color-writing);
}

.bg__gray {
  opacity: 0.3 !important;
  background: var(--color-gray-6) !important;
}

.content__gray {
  p {
    color: var(--color-gray-6) !important;
  }
}

.fill__gray {
  fill: var(--color-gray-6) !important;

  g, path {
    fill: var(--color-gray-6) !important;
  }
}

.bg__grammar {
  background: var(--color-grammar);
}

.bg__listening {
  background: var(--color-listening);
}

.our-text-right {
  text-align: end;
}

.bg__reading {
  background: var(--color-reading);
}

.bg__speaking {
  background: var(--color-speaking);
}

.bg__writing {
  background: var(--color-writing);
}

.icon__grammar {
  path {
    fill: var(--color-grammar);
  }
}

.icon__listening {
  path {
    fill: var(--color-listening);
  }
}

.icon__reading {
  path {
    fill: var(--color-reading);
  }
}

.icon__speaking {
  path {
    fill: var(--color-speaking);
  }
}

.icon__writing {
  path {
    fill: var(--color-writing);
  }
}

.icon__not__filled {
  opacity: 0.5;
}

.our-text-sec {
  color: var(--secOto) !important;
}

.img__pixelated {
  image-rendering: pixelated;
}

.pr-0 {
  padding-inline-end: 0;
}

.margin__right {
  margin-inline-end: 1rem;
}

.btn__sec {
  font-size: var(--font-size-20) !important;
  color: var(--color-white) !important;
  background: var(--oto-blue) !important;
}

.table__box__shadow {
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%) inset;
}

.page__title {
  font-size: var(--font-size-28);
  font-weight: var(--fw-bold);
  margin: 2rem auto;
  text-align: center;
  text-transform: capitalize;
  color: var(--mainColor);
}

.our-font-color {
  color: var(--fontColor) !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: var(--fw-semiBold);
}

.fw-700 {
  font-weight: var(--fw-bold);
}

.justify-self-center {
  justify-self: center;
}

.cursorPointer {
  cursor: pointer !important;
}

.cursorDisabled {
  cursor: not-allowed !important;
}

.our__text-left {
  text-align: start;
}

.our__text-right {
  text-align: end;
}

.hidden {
  display: none;
}

.main-color {
  color: var(--mainColor) !important;
}

.success-text {
  color: var(--successColor) !important;
}

.semi__title {
  font-size: var(--font-size-16);
  font-weight: var(--fw-regular);
  margin-bottom: 0;
}

.semi__title-op {
  opacity: 0.7;
}

%ourChip {
  @include flex(center, center, row, nowrap);
  align-items: center;
  height: 1px;
  min-height: 32px;
  margin: 4px;
  text-align: center;
  color: var(--color-white);
  border-radius: var(--rd-16);
}

.our-chip {
  @extend %ourChip;
  width: 125px;
  padding: 7px 12px;
}

.our-chip2 {
  @extend %ourChip;
  width: auto;
  padding: 8px 16px;
}

.our-chip3 {
  @extend %ourChip;
  width: 144px;
  min-width: 144px;
  padding: 4px 6px;
}

@include media-breakpoint-down(md) {
  .our-chip2 {
    width: 140px;
    padding: 8px 12px;
  }
}

.no__rate {
  font-size: var(--font-size-12);
  box-sizing: border-box;
  padding: 0 1rem;
  text-transform: uppercase;
  color: var(--oto-blue);
  border: 1px solid var(--oto-blue);
  border-radius: 50px;
}

.border__icon {
  border: 1px var(--mainColor) solid;
  border-radius: var(--rd-circle);
}

.show-less {
  overflow: hidden;
  height: 4rem;
  padding: 1rem;
}

.show-less-3lines {
  @extend %paragraph-3-lines;
}

.show-less-2lines {
  @extend %paragraph-2-lines;
}

.show-more {
  display: inline-block;
  overflow: visible;
}

.p__title {
  font-weight: var(--fw-regular);
  opacity: 0.6;
}

.p__title__bold {
  font-weight: var(--fw-medium);
  opacity: 1;
}

.p__title2 {
  font-weight: var(--fw-regular);
  opacity: 0.8;
}

.p__body {
  font-weight: var(--fw-medium);
  opacity: 1;
}

.vertical-baseline {
  vertical-align: baseline !important;
}

.load__more__btn {
  margin: 0.5rem auto;
  border: 1px solid;
  border-radius: var(--rd-24);
}

.active__gateway {
  box-sizing: border-box;
  padding: 1rem 0.6rem !important;
  border-radius: var(--rd-16);
  background: var(--semi-gray);
}

.old__price {
  margin: 0 0.5rem;
  text-decoration: line-through;
  color: var(--redColor) !important;
}

.new__price {
  margin: 0 0.5rem;
  color: var(--mainColor) !important;
}

.container-main {
  overflow-x: hidden;
  width: 95%;
  margin: 1rem auto;
}

.page-title {
  .box-title {
    padding: 64px 0;
    text-align: center;
    border-radius: var(--rd-16);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    h1 {
      font-size: var(--font-size-48);
      font-weight: var(--fw-bold);
      color: var(--color-white);
    }
  }
}

@include media-breakpoint-down(md) {
  .page-title {
    .box-title {
      padding: 24px 0;

      h1 {
        font-size: var(--font-size-32);
        font-weight: var(--fw-bold);
      }
    }
  }
}

.neon__shadow {
  transition: 0.3s;
  animation: glow 1s infinite;
}

.noSessions__action {
  margin: 0.5rem auto;
  padding: 0.2rem 4rem;
  border-radius: var(--rd-8);
}

@media (max-width: 998px) {
  .btn {
    font-size: var(--font-size-12);
  }
  .page-title {
    .box-title {
      padding: 50px 0;

      h1 {
        font-size: var(--font-size-28);
        font-weight: var(--fw-bold);
      }
    }
  }
  .breadcrumb {
    .breadcrumb-item {
      font-size: var(--font-size-16);
    }
  }
}

.fxFill {
  width: 100%;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
}

.fxMainBar {
  display: flex;
  align-items: baseline;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.fxButton {
  display: none;
  @include media-breakpoint-down(lg) {
    display: block;
  }
}

.fxHotline {
  display: block;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

%our__button {
  font-size: var(--font-size-16);
  font-weight: var(--fw-bold);
  position: relative;
  width: auto;
  padding: 0.5rem 1rem;
  transition: var(--transition-300ms);
  color: var(--color-white);
  border: 1px solid var(--semi-gray);
  border-radius: var(--rd-32);
  background: var(--semi-gray);

}

@mixin our-btn-colors($color,$color-hover) {
  border: 1px solid $color;
  background: $color;

  &:hover {
    background: $color-hover;
    color: $color;
  }
}

@mixin our-stroked-btn-colors($color) {
  border: 1px solid $color;
  background: var(--color-white);
  color: $color;

  &:hover {
    background: $color;
    color: var(--color-white);
  }

}

.our__button {
  @extend %our__button;

  &.blue__button {
    @include our-btn-colors(var(--oto-blue), var(--color-white));
  }

  &.gray__button {
    @include our-btn-colors(var(--color-gray-0), var(--color-white));
  }

  &.red__button {
    @include our-btn-colors(var(--redColor), var(--color-white));
  }

  &.yellow__button {
    @include our-btn-colors(var(--mainColor), var(--color-white));
  }

  &.green__button {
    @include our-btn-colors(var(--color-grammar), var(--color-white));
  }
}

.our__stroked__button {
  @extend %our__button;

  &.blue__button {
    @include our-stroked-btn-colors(var(--oto-blue));
  }

  &.gray__button {
    @include our-stroked-btn-colors(var(--oto-blue));
  }

  &.red__button {
    @include our-stroked-btn-colors(var(--redColor));
  }

  &.yellow__button {
    @include our-stroked-btn-colors(var(--mainColor));
  }

  &.green__button {
    @include our-stroked-btn-colors(var(--color-grammar));
  }

  &.btn__icon {
    &:hover {
      .icon {
        svg {
          fill: var(--color-white);
        }
      }
    }
  }
}

.big__btn {
  width: 175px;
  min-width: 175px;
}

.ultra__btn {
  width: auto;
  min-width: 250px;
}

.button__disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--color-gray-5);
  border-color: var(--semi-gray);
  background: var(--semi-gray);
}

.btn__icon {
  @include flex(center, center, row, nowrap);

  .icon {
    margin-inline-end: 0.5rem;
  }
}

.block__container {
  @extend %block__data;
}

.block__title {
  position: absolute;
  top: -9.6px;
  left: 16px;
  color: var(--mainColor);
  background: var(--color-white);
}

.exam__result__content {
  font-size: var(--font-size-18);
  font-weight: var(--fw-medium);
  width: 150px;
  max-width: 225px;
  margin: 0.4rem auto;
  padding: 0.5rem 1rem;
  text-align: center;
  color: var(--color-white);
  border-radius: var(--rd-10);

  &.red {
    background: var(--color-speaking);
  }

  &.blue {
    background: var(--color-writing);
  }

  &.green {
    background: var(--oto-green);
  }

  &.gray {
    background: var(--color-gray-6);
  }
}

.card__box__shadow {
  margin: 1rem auto;
  border-radius: 24px;
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
}

.p-end-0 {
  padding-inline-end: 0;
}

.course__actions {
  font-size: var(--font-size-16);
  font-weight: var(--fw-medium);
  margin: 0 0.5rem;
  transition: var(--transition-200ms);
  color: var(--secOto);
  border: 0;
  background: transparent;
  @include flex(baseline, center, row, nowrap);

  &.white {
    background: var(--color-white);
  }

  .btn__span {
    margin: 0 0.2rem;
    text-decoration: underline;
  }

  &:hover {
    color: var(--secOto);

    .btn__span {
      text-decoration: none;
    }
  }
}

.nodata__note {
  font-size: 1.2rem;
  margin-top: 1rem;
  text-align: center;
  color: var(--secOto);
}

.table__header {
  font-size: var(--font-size-22);
  font-weight: var(--fw-bold);
  margin-bottom: 0.5rem;
  color: var(--fontColor);
}

.table__timezone {
  font-size: var(--font-size-14);
  font-style: italic;
  margin-bottom: 0.2rem;
  color: var(--font-color-light);
}

.ngx-pagination .current {
  color: var(--color-white) !important;
  border-radius: var(--rd-4);
  background: var(--secOto) !important;
}

.ngx-pagination {
  text-align: center;
}

.ngx-pagination a:hover {
  color: var(--color-white);
  border-radius: var(--rd-4);
  background: var(--light-grey) !important;
}

.teachers-dialog-container {
  min-width: 60vw;
  max-width: 85vh;
  min-height: 60vh;
  max-height: 90vh;
  @include media-breakpoint-down(md) {
    max-width: 95vw;
    min-height: auto;
    max-height: 95vh;
  }
}

.rotate-rtl {
  transform: rotate(180deg);
}

.rotate-rtl-negative {
  transform: rotate(-180deg);
}
